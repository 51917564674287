function setConsentCookie(value) {
	const domain = window.location.hostname;
	document.cookie = `userConsent=${value}; max-age=31536000; path=/; domain=${domain}; secure; SameSite=Strict`;
}

function getConsentStatus() {
	return document.cookie
		.split("; ")
		.find((row) => row.startsWith("userConsent="))
		?.split("=")[1];
}

window.addEventListener("load", function () {
	var didConsent = getConsentStatus() === "true";
	window.cookieconsent.initialise({
		palette: {
			popup: { background: "#edeff5", text: "rgb(68, 68, 68)" },
			button: { background: "#4b81e8" },
		},
		theme: "classic",
		content: {
			message: "This website uses cookies to ensure you get the best experience on our website.",
			dismiss: "Accept",
			deny: "Refuse",
			link: "Learn more",
			href: "../assets/Recruitroo_Privacy_Policy_2024.pdf",
		},
		onInitialise: function (status) {
			var type = this.options.type;
			setConsentCookie(didConsent);
			if (type == "opt-in" && didConsent) {
				!(function (f, b, e, v, n, t, s) {
					if (f.fbq) return;
					n = f.fbq = function () {
						n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
					};
					if (!f._fbq) f._fbq = n;
					n.push = n;
					n.loaded = !0;
					n.version = "2.0";
					n.queue = [];
					t = b.createElement(e);
					t.async = !0;
					t.src = v;
					s = b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t, s);
				})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
				fbq("init", "1312110242776760");
				fbq("track", "PageView");
			}
			if (!didConsent) {
				// Do not initialise cookies
			}
		},
		onStatusChange: function (status, chosenBefore) {
			var type = this.options.type;
			var didConsent = this.hasConsented();
			setConsentCookie(didConsent);
			if (type == "opt-in" && didConsent) {
				!(function (f, b, e, v, n, t, s) {
					if (f.fbq) return;
					n = f.fbq = function () {
						n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
					};
					if (!f._fbq) f._fbq = n;
					n.push = n;
					n.loaded = !0;
					n.version = "2.0";
					n.queue = [];
					t = b.createElement(e);
					t.async = !0;
					t.src = v;
					s = b.getElementsByTagName(e)[0];
					s.parentNode.insertBefore(t, s);
				})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
				fbq("init", "1312110242776760");
				fbq("track", "PageView");
			}
			if (!didConsent) {
				// Clear Facebook Pixel cookies
				document.cookie.split(";").forEach(function (c) {
					var cookieName = c.trim().split("=")[0];
					if (cookieName.startsWith("_fbp") || cookieName.startsWith("fbsr")) {
						document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
					}
				});
			}
			// Update the didConsent variable
			window.didConsent = didConsent;
		},
		onRevokeChoice: function () {
			var type = this.options.type;
			if (type == "opt-in") {
				// Clear Facebook Pixel cookies
				document.cookie.split(";").forEach(function (c) {
					var cookieName = c.trim().split("=")[0];
					if (cookieName.startsWith("_fbp") || cookieName.startsWith("fbsr")) {
						document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
					}
				});

				// Reload the page to stop all tracking scripts
				location.reload();
			}
		},
		type: "opt-in",
		revokable: true,
		law: {
			regionalLaw: false,
		},
		location: false,
	});
});
